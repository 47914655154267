import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from './home.js';
import About from './About';
import Terms from './legal/Terms.js';
import BusinessDetails from './business/BusinessDetails';
import BusinessList from './business/BusinessList';
import PersonDetails from './person/PersonDetails';
import Privacy from './legal/Privacy.js';
import BusinessCategory from './business/BusinessCategory.js';
import Search from './Search.js';
import Pricing from './Pricing.js';
import Map from './Map.js';

const Pages = () => {
    return(
        <Router>
            <Routes>
                <Route exact path="/" element= {<Home/>} />
                <Route path = "/business/"                  element = {<BusinessList/>} />
                <Route path = "/business/category/:slug"    element = {<BusinessCategory/>} />
                <Route path = "/business/:slug"             element = {<BusinessDetails/>} />
                <Route path = "/person/:slug"               element = {<PersonDetails/>} />
                <Route path = "/about"                      element = {<About/>} />
                <Route path = "/search"                     element = {<Search/>} />
                <Route path = "/terms"                      element = {<Terms/>} />
                <Route path = "/privacy"                    element = {<Privacy/>} />
                <Route path = "/map"                        element = {<Map/>} />
                <Route path = "/pricing"                    element = {<Pricing/>} />
            </Routes>
        </Router>
    );
};

export default Pages;