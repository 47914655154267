import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faContactBook, faMapMarkerAlt, faPhone, faRoute } from '@fortawesome/free-solid-svg-icons';


export default function ContactBox(props) {
    const business = props.business;
    if (parseInt(business.membership)>0) {
        return (
            <>
                
                <div className={"p-4 w-full bg-white rounded-lg border shadow-md sm:p-6 dark:bg-gray-800 dark:border-gray-700"}>
                                        <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
                                            Contact
                                        </h5>
                                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Contact this business via any medium listed below.</p>
                                        <ul className="my-4 space-y-3">
                                            <li>
                                                <a href={"https://www.google.com/maps/place/" + business.lat+ ","+ business.lng +"/@" + business.lat+ ","+ business.lng +",16z/"} className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faMapMarkerAlt} /> Location</span>
                                                    <span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">Popular</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"https://www.google.com/maps/dir/" + business.lat+ ","+ business.lng +"/@" + business.lat+ ","+ business.lng +",16z/"} className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faRoute} /> Directions</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"tel:+" + business.country +business.phone } className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faPhone} /> Call</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"https://wa.me/" + business.country +business.phone } className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faContactBook} /> WhatsApp</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div>
                                            <a href="#" className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400">
                                                </a>
                                        </div>
                                    </div>
    
            </>
        );
    } else {
        return (
            <>
                
                <div className={"p-4 w-full max-w-sm bg-white rounded-lg border shadow-md sm:p-6 dark:bg-gray-800 dark:border-gray-700"}>
                                        <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
                                            Contact
                                        </h5>
                                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Contact Information not available for unverified businesses.</p>
                                        <ul className="blur-sm my-4 space-y-3">
                                            <li>
                                                <a className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faMapMarkerAlt} /> Location</span>
                                                    <span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">Popular</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faRoute} /> Directions</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faPhone} /> Call</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="flex items-center p-3 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                                    <span className="flex-1 ml-3 whitespace-nowrap"><FontAwesomeIcon icon={faContactBook} /> WhatsApp</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div>
                                            <a href="#" className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400">
                                                Request Contact Details</a>
                                        </div>
                                    </div>
    
            </>
        );
    }
    
}