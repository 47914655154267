import React, {useState,useEffect} from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

export default function Search() {
    const [search, setSearch] = useState("");
    const [searchBoxValue, setSearchBoxValue] = useState("");
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    //const [searchParams, setSearchParams] = useSearchParams();

    const handleSubmit=(event)=>{
        event.preventDefault();
        const form = event?.target;
        //console.log(form.elements[0].value);
        //setSearchParams({"q": form.elements[0].value});
        setSearch(form.elements[0].value);
    }

    useEffect(() => {
        setSearchBoxValue(new URLSearchParams(new URL(window.location.href).search).get('q'));
    }, []);
    const getSearchResults = ()=>{
        if (search!=="") {
            fetch("https://api.rice.directory/search?q="+ search)
                .then(res => res.json())
                .then(
                    (data) => {
                        setIsLoaded(true);
                        console.log(data);
                        setBusinesses(data);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
            )
        }else{
            setSearch(new URLSearchParams(new URL(window.location.href).search).get('q'));
        }
    }
    useEffect(()=>{
        setIsLoaded(false);
        setBusinesses([]);
        getSearchResults();
    },[search]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <div className="bg-slate-100 border-gray-200 dark:bg-gray-900 mt-20">
                <Navbar selected="blog" />
                <section>
                    <div className="grid max-w-screen-xl px-4 py-8 mx-auto ">
                        <form action='../search' class="flex items-center">   
                            <label for="simple-search" class="sr-only">Search</label>
                            <div class="relative w-full">
                                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                </div>
                                <input type="text" name='q' id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" value={searchBoxValue} onChange={e => setSearchBoxValue(e.target.value)} required=""/>
                            </div>
                            <button type="submit" class="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </form>
                    </div>
                </section>
                <div className="columns-1 md:columns-3 min-h-[100vh]">
                    <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-700 rounded"></div>
                                <div className="space-y-3">
                                    <div className="grid grid-cols-3 gap-4">
                                    <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                    <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                                    </div>
                                    <div className="h-2 bg-slate-700 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border border-blue-300 shadow rounded-md p-4 m-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-slate-700 rounded"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-slate-700 rounded"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="border border-blue-300 shadow rounded-md p-4 m-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-slate-700 rounded"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-slate-700 rounded"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className='bg-slate-100 border-gray-200 dark:bg-gray-900 min-h-[100vh] '>
                <Navbar selected="search"/>
                <div className='mt-20'>
                <section className="bg-slate-100 dark:text-white dark:bg-slate-800">
                    <div className="grid max-w-screen-xl px-4 py-8 mx-auto ">
                        <form action='../search' class="flex items-center" autocomplete="off" onSubmit={handleSubmit}>   
                            <label for="simple-search" class="sr-only">Search</label>
                            <div class="relative w-full">
                                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                </div>
                                <input type="text" name='q' id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" value={searchBoxValue} onChange={e => setSearchBoxValue(e.target.value)} required=""/>
                            </div>
                            <button type="submit" class="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </form>
                    </div>
                </section>
            </div>
            <div className='bg-slate-100 dark:text-white dark:bg-slate-800'>
                <div className=' container mx-auto p-4 '>
                    <div className='lg:grid lg:grid-cols-3 lg:gap-4'>
                    {businesses.map(business => (
                        <div key={(business.slug).toString()}>
                            <Link to={`../business/${business.slug}`}>
                                <div className="w-full rounded overflow-hidden shadow-lg bg-slate-50 border-gray-200 dark:bg-gray-900 dark:border-gray-700 mb-4">
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2 text-gray-900 dark:text-white">{parse(business.name)} {(business.membership > 0)? <span class="inline-flex items-center p-1 mr-2 text-sm font-semibold text-white bg-sky-500 rounded-full dark:bg-sky-500 dark:text-white">
                                            <svg aria-hidden="true" class="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span class="sr-only">Verified</span>
                                        </span>:""}</div>
                                        <div className="text-base text-gray-700 dark:text-gray-400">
                                            {parse(business.address)}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
                <Footer />
            </div>
        );
    }
}