import React, { useState, useEffect }  from 'react';
import { Link,useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import parse from 'html-react-parser';

export default function BusinessCategory() {
    const { slug } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    useEffect(() => {
        fetch("https://api.rice.directory/business/category/"+ slug)
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setBusinesses(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <div className="bg-slate-100 border-gray-200 dark:bg-gray-900 mt-20">
                <Navbar selected="blog" />
                <div className="columns-1 md:columns-3 min-h-[100vh]">
                    <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-700 rounded"></div>
                                <div className="space-y-3">
                                    <div className="grid grid-cols-3 gap-4">
                                    <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                    <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                                    </div>
                                    <div className="h-2 bg-slate-700 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border border-blue-300 shadow rounded-md p-4 m-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-slate-700 rounded"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-slate-700 rounded"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="border border-blue-300 shadow rounded-md p-4 m-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-slate-700 rounded"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-slate-700 rounded"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className='bg-slate-100 border-gray-200 dark:bg-gray-900 mt-20'>
                <Navbar selected="blog"/>
                <div className='container mx-auto p-4'>
                    <div className='columns-1 md:columns-3'>
                        {businesses.map(business => (
                        <div key={(business.slug).toString()}>
                            <Link to={`../business/${business.slug}`}>
                                <div className="w-full rounded overflow-hidden shadow-lg bg-slate-50 border-gray-200 dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2 text-gray-900 dark:text-white">{parse(business.name)} {(business.membership > 0)? <span class="inline-flex items-center p-1 mr-2 text-sm font-semibold text-white bg-sky-500 rounded-full dark:bg-sky-500 dark:text-white">
                                            <svg aria-hidden="true" class="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span class="sr-only">Verified</span>
                                        </span>:""}</div>
                                        <div className="text-base text-gray-700 dark:text-gray-400">
                                        {parse(business.address)}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        ))}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}