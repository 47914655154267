import React from 'react';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';

export default function Home() {
    return (
        <>
            <Navbar selected="home"/>
            <Header />
            <Footer />
        </>
    );
}