import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroRiceBowl from '../assets/rice_bowl.png';
import Footer from './components/Footer';

export default function About() {
    return (
        <>
            <Navbar selected="about"/>
            <div className=' mt-20'>
                <section className="bg-slate-100 dark:text-white dark:bg-slate-800">
                    <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                        <div className="mr-auto place-self-center lg:col-span-7">
                            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Explore Rice,<br/>Explore Rice Directory.</h1>
                            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">From paddy in fields to global rice distribution, companies around the world use Rice Directory to connect with high valued businesses.</p>
                            <Link to="/" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center dark:text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                                Get started
                                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </Link>
                            <a href="mailto:sales@rice.directory" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                                Speak to Sales
                            </a> 
                        </div>
                        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                            <img src={HeroRiceBowl} alt="mockup"/>
                            {/* <img src="https://api.thegreenwebfoundation.org/greencheckimage/rice.directory?nocache=true" alt="This website is hosted Green - checked by thegreenwebfoundation.org"/> */}
                        </div>                
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}