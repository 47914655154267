import React, {useState, useEffect} from "react";
import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
//import "leaflet/dist/leaflet.css"
import { Link } from 'react-router-dom';
import '../App.css';
//import { Icon } from "leaflet";
//import * as parkData from "./data/skateboard-parks.json";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default function Map() {
  const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    useEffect(() => {
        fetch("https://api.rice.directory/business")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setBusinesses(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, []);
      if (error) {
        return <div>{error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div>
          <Navbar selected="map"/>
      <div className='mx-auto z-40 min-h-screen w-full'>
        <MapContainer className="z-0 min-h-screen" center={[23,80]} zoom={5} scrollWheelZoom={false} zoomControl={false}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url=" https://cdn.lima-labs.com/{z}/{x}/{y}.png?api=7aKj8ahwejj8yI23Slcao8JwakkdA00001" />
          <ZoomControl position="bottomright"/>
        </MapContainer>
      </div>
      <Footer/>
        </div>
      );
    } else {
      return (
        <div>
          <Navbar selected="map"/>
      <div className='mx-auto z-40 min-h-screen w-full'>
        <MapContainer className="z-0 min-h-screen" center={[23,80]} zoom={5} scrollWheelZoom={false} zoomControl={false}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url=" https://cdn.lima-labs.com/{z}/{x}/{y}.png?api=7aKj8ahwejj8yI23Slcao8JwakkdA00001" />
          <ZoomControl position="bottomright"/>
          {businesses.map(business => (
                        <div key={(business.slug).toString()} className="text-black">
                            <Marker position={[business.lat,business.lng]} >
                              <Popup>
                                <Link to={'../business/'+business.slug}>{business.name}</Link>
                              </Popup>
                            </Marker>
                        </div>
                        ))}
        </MapContainer>
      </div>
      <Footer/>
        </div>
      );
    }
}