import React  from 'react';
import { Link } from 'react-router-dom';

export default function DetailsTab(props) {
    const business = props.business;
    if (parseInt(business.membership)>0) {
        return (
            <>
                <div className="my-4">
                    <div className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Description</h5>
                        <p className="font-normal text-gray-700 dark:text-gray-400">{business.description}</p>
                    </div>
                </div>

                <div className="my-4">
                    <div className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Products</h5>
                        <div>
                            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                                {business.products.map(product => (
                                <div key={(product.id).toString()}>
                                    <div className="lg:col-span-1 mb-4">
                                        <div className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700">
                                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={"https://image.rice.directory/"+product.image+".webp"} alt=""/>
                                            <div className="flex flex-col justify-between w-full p-4 leading-normal">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{product.name}</h5>
                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{product.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-4">
                    <div className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">People</h5>
                        <div>
                            <div className="lg:grid lg:grid-cols-2 lg:gap-2">
                                {business.persons.map(persona => (
                                <div key={(persona.id).toString()}>
                                    <Link to={'../person/'+persona.person.slug} className="lg:col-span-1 mb-4">
                                        <div className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700">
                                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={"https://image.rice.directory/"+persona.person.image+".webp"} alt=""/>
                                            <div className="flex flex-col justify-between w-full p-4 leading-normal">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{persona.person.name}</h5>
                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{persona.person.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <div className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Nearby Businesses</h5>
                        <div>
                            <div className="lg:grid lg:grid-cols-2 lg:gap-2 lg:flex-row">
                                {business.nearby.map(business => (
                                <div key={(business.id).toString()}>
                                    {/* This need to be fixed */}
                                    <a href={'/business/'+(business.slug)} className="lg:col-span-1">
                                        <div className="mb-4 flex items-center bg-white rounded-lg border shadow-md flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700">
                                            {/* <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={"https://api.rice.directory/image/"+business} alt=""/> */}
                                            <div className="flex flex-col justify-between p-4 leading-normal">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{business.name}</h5>
                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{business.address}</p>
                                                <div className="text-base text-gray-700 dark:text-gray-400">{((Math.sqrt((business.distance))*1.6) >= 1 )? parseInt(Math.sqrt((business.distance))*1.6)+" km away":parseInt(Math.sqrt((business.distance))*1.6 *1000)+" m away"}</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="my-4">
                    <div className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Description</h5>
                        <p className="font-normal text-gray-700 dark:text-gray-400">{business.description}</p>
                    </div>
                </div>

                <div className="my-4">
                    <div className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Nearby Businesses</h5>
                        <div>
                            <div className="lg:grid lg:grid-cols-2 lg:gap-2 lg:flex-row">
                                {business.nearby.map(business => (
                                <div key={(business.id).toString()}>
                                    {/* This need to be fixed */}
                                    <a href={'/business/'+(business.slug)} className="lg:col-span-1">
                                        <div className="mb-4 flex items-center bg-white rounded-lg border shadow-md flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700">
                                            {/* <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={"https://api.rice.directory/image/"+business} alt=""/> */}
                                            <div className="flex flex-col justify-between p-4 leading-normal">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{business.name}</h5>
                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{business.address}</p>
                                                <div className="text-base text-gray-700 dark:text-gray-400">{((Math.sqrt((business.distance))*1.6) >= 1 )? parseInt(Math.sqrt((business.distance))*1.6)+" km away":parseInt(Math.sqrt((business.distance))*1.6 *1000)+" m away"}</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    
}